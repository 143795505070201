import React from "react"
import { StaticQuery } from "gatsby"
import PrimaryLayout from "../../layouts/layout"
import * as Animations from "../../animations"
import Div100vh from "react-div-100vh"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import { pageTransitionEnter, pageTransitionExit } from "../../animations"

class NotFound extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.data.allWordpressPage.edges,
    }
  }

  formPoints() {
    const rowsArr = []
    let pointsCount = 1
    for (let i = 0; i < 5; i++) {
      const pointsArr = []
      rowsArr.push(pointsArr)
      for (let u = 0; u < 9; u++) {
        rowsArr[i].push(pointsCount)
        pointsCount++
      }
    }
    return rowsArr
  }

  getCurrentLang() {
    const langs = ["en", "fr", "it", "de"]
    const currentUrl = typeof window !== "undefined" ? window.location.href : ""
    const currentLang = langs.find(lang => lang === currentUrl.split("/")[3])
    return currentLang !== undefined ? currentLang : "en"
  }

  render() {
    const currentLang = this.getCurrentLang()
    console.log(currentLang)

    const content = this.state.content.filter(
      page =>
        page.node.template === "TemplateHome.php" &&
        page.node.polylang_current_lang.split("_")[0] === currentLang
    )[0].node.acf.not_found

    const points = this.formPoints()
    let rowCounter = 0
    return (
      <PrimaryLayout
        intro={false}
        tpl="NotFound"
        scrollbarless={true}
        lang="it"
        footerless={true}
      >
        <Div100vh id="not-found">
          <div className="points points--preload points--404">
            <div className="grid grid--points">
              {points.map(row => {
                rowCounter++
                return (
                  <div className="points__row" key={`row-${rowCounter}`}>
                    {row.map(point => {
                      return (
                        <div
                          className={`point point--preload point--${point}`}
                          key={`preload-${point}`}
                        >
                          <span
                            className="bubble-parent"
                            onMouseEnter={e => {
                              Animations.bubble(
                                e.target.children[0],
                                e.target.closest(".bubble-parent")
                              )
                            }}
                          >
                            <span className="bubble" />
                          </span>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>

          <div className="grid grid--full-height">
            <div className="not-found__content">
              {content.title && <h1 className="white">Pagina non trovata</h1>}
              {content.subtitle && <p>Spiacenti, impossibile procedere!</p>}
              {content.button_title && (
                <TransitionLink
                  timeout={1000}
                  to="/it/"
                  className="btn btn--arrow btn--arrow--big btn--arrow--left"
                  exit={pageTransitionExit()}
                  entry={pageTransitionEnter()}
                >
                  Ritorno
                </TransitionLink>
              )}
            </div>
          </div>
        </Div100vh>
      </PrimaryLayout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage {
          edges {
            node {
              wordpress_id
              title
              template
              polylang_current_lang
              acf {
                not_found {
                  title
                  subtitle
                  button_title
                }
              }
            }
          }
        }
      }
    `}
    render={data => <NotFound data={data} {...props} />}
  />
)
